// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, clearStorageData, generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
import { FormikProps, FormikTouched, FormikErrors } from 'formik';
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import React from 'react';
import { difference } from "lodash";
const baseURL = require("../../../framework/src/config.js").baseURL;
import { mixPanelEventHit } from "../../../components/src/Mixpanel";
import { processImage, uploadImageToS3 } from "../../../components/src/imageUploader";
import * as H from 'history';
// Customizable Area End

// Customizable Area Start
import {
  veg_icon,
  nonVeg_icon,
  vegan_icon,
  glutenfree_icon,
  halal_icon,
  kosher_icon,
  eggs_icon,
  nuts_icon,
  wheat_icon,
  soy_icon,
  peanuts_icon,
  celery_icon,
  fish_icon,
  crustaceans_icon,
  lupin_icon,
  mollusc_icon,
  mustard_icon,
  sesameseeds_icon,
  sulphurdioxide_icon,
  milk_icon,
  mild_icon,
  spicy_icon,
  extraspicy_icon
} from "./assets";

export interface MenuItemsFormatted {
  id: number;
  name: string;
  price: number;
  after_discount: number;
  status: boolean;
  catagories: string[];
  availability: string;
  food_lables: string[];
  checked: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  successfulUploads: number;
  failedUploads: number;
  errorCSVFile: any;
  importInProgress: boolean;
  importPopup: boolean;
  importStatus: string;
  progress: number;
  product_title: any;
  product_desc: any;
  product_category: any;
  product_price: any;
  discount_checked: boolean;
  product_discountValue: any;
  product_discountPrice: any;
  product_selectedImage: any;
  product_previewImage: any;
  product_stock: any;
  product_dietary: any;
  product_allergens: any;
  product_spicelevel: any;
  productsData: any;
  categoriesData: any;
  isEdit: boolean;
  isAddEdit: boolean;
  categoryId: any;
  categoryName: string;
  setTouched: boolean;
  loading: boolean;
  loadingSearch: boolean;
  errorText: string;
  searchCategory: string;
  noSearchCategories: boolean;
  product_status: boolean;
  product_Id: any;
  dietaryData: any;
  allergenData: any;
  spiceLevelData: any;
  image_check: any;
  titleCount: any;
  descCount: any;
  imageError: any;
  openAlert: boolean;
  alertSuccess: boolean;
  alertMessage: any;
  openSubmit: boolean;
  InputValue: any;
  inputValueVariant: any;
  productVariants: any;
  searchProductVal: any;
  productSearchData: any;
  deleteVariantId: any;
  productStatus: any;
  foodLablesData: any;
  selectedFoodLabels: any;
  appliedFoodLables: any;
  isFoodLabelOpen: any;
  isDialogOpen: any;
  pagination: any;
  packageHeight: number | string;
  packageBreadth: number | string;
  packageWidth: number | string;
  packageWeight: number | string;
  expanded: string | boolean;
  menuAnchorEl: null | HTMLElement;
  dialogCsvOpen: boolean;
  fileCsvClose: boolean;
  selectedCsvFile: File | undefined | null;
  csvFileError: string;
  showCsvUploadDialog: boolean;
  allChecked: boolean;
  checked: boolean;
  checkedImageCatalogueList: boolean;
  openDeleteDialog: boolean;
  selectedItemCount: number;
  imageSignedId: any;
  compressedFiles: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export type FormikItemValues = {
  product_title: string;
  product_desc: string;
  product_price: number;
  product_category: string[];
  product_discountValue: number | string;
  product_stock: boolean;
  product_image: string;
  packageHeight: number | string;
  packageWidth: number | string;
  packageBreadth: number | string;
  packageWeight: number | string;
}

export interface ItemMenuFilter {
  name: string
  value: string
  id: number
  img: string
}
// Customizable Area End

export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formikRef: React.RefObject<FormikProps<FormikItemValues>> = React.createRef();
  getOrderNotificationId: string = ""
  getCategoriesApiCallId = "";
  addCategoryApiCallId = "";
  addProductApiCallId = "";
  editProductApiCallId = "";
  postMenuItemsCallId = "";
  searchProductApiCallId = "";
  searchSuggestiveProductApiCallId = "";
  deleteProductVariantApiCallId = "";
  imageVariantsApiCallId = "";
  getCurrencyTypeApiCallId = "";
  downloadSampleItemsId: string = "";
  uploadCsvFileApiId: string = "";
  currencyType: any
  unblock: any
  importCSVProgressCallId = "";
  errorCSVCallId = "";
  resetProcessCallId = "";
  deleteMenuItemsApiId: string = "";
  mixPanelInfo : {buildCardId : number, emailId : string}= {buildCardId : 0, emailId: ""}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.PostEntityMessage)
      // Customizable Area End
    ];

    this.setCurrencyType();
    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      // Customizable Area Start
      successfulUploads: 0,
      failedUploads: 0,
      errorCSVFile: '',
      importStatus: '',
      importInProgress: false,
      importPopup: false,
      progress: 0,
      product_title: "",
      product_desc: "",
      product_category: [],
      product_price: "",
      discount_checked: false,
      product_discountValue: "",
      product_discountPrice: 0,
      product_selectedImage: null,
      product_previewImage: null,
      product_stock: "",
      product_dietary: [],
      product_allergens: [],
      productVariants: [],
      product_spicelevel: "",
      product_Id: "",
      imageError: "Image is required!",
      openAlert: false,
      alertSuccess: false,
      alertMessage: "",
      openSubmit: false,
      InputValue: "",
      inputValueVariant: "",
      searchProductVal: "",
      productsData: [],
      categoriesData: [],
      productSearchData: [],
      isEdit: false,
      isAddEdit: false,
      categoryId: 0,
      categoryName: "",
      setTouched: false,
      loading: false,
      loadingSearch: false,
      errorText: "",
      searchCategory: "",
      noSearchCategories: false,
      product_status: false,
      image_check: false,
      titleCount: 0,
      descCount: 0,
      deleteVariantId: "",
      productStatus: false,
      dietaryData: [
        { id: 1, img: veg_icon, name: "Vegetarian", value: "Vegetarian", color: "#DDFFCF" },
        { id: 2, img: nonVeg_icon, name: "Non-Vegetarian", value: "Non-Vegetarian", color: "#FFA6A6" },
        { id: 3, img: vegan_icon, name: "Vegan", value: "Vegan", color: "#C4E1BD" },
        { id: 4, img: glutenfree_icon, name: "Gluten free", value: "Gluten free", color: "#EFE1B0" },
        { id: 5, img: halal_icon, name: "Halal", value: "Halal", color: "#D4EDCA" },
        { id: 6, img: kosher_icon, name: "Kosher", value: "Kosher", color: "#CAE2FF" },
      ],
      allergenData: [
        { id: 1, img: milk_icon, name: "Milk", value: "Milk", color: "#F2F2F2" },
        { id: 2, img: eggs_icon, name: "Eggs", value: "Eggs", color: "#FFFDD6" },
        { id: 3, img: nuts_icon, name: "Nuts", value: "Nuts", color: "#E1C9C1" },
        { id: 4, img: wheat_icon, name: "Wheat", value: "Wheat", color: "#E4D7A6" },
        { id: 5, img: soy_icon, name: "Soy", value: "Soy", color: "#FCEDAA" },
        { id: 6, img: peanuts_icon, name: "Peanuts", value: "Peanuts", color: "#F8D494" },
        { id: 7, img: celery_icon, name: "Celery", value: "Celery", color: "#9ECF9B" },
        { id: 8, img: fish_icon, name: "Fish", value: "Fish", color: "#BAD7F1" },
        { id: 9, img: crustaceans_icon, name: "Crustaceans", value: "Crustaceans", color: "#D2EAFF" },
        { id: 10, img: lupin_icon, name: "Lupin", value: "Lupin", color: "#E7D6F4" },
        { id: 11, img: mollusc_icon, name: "Mollusc", value: "Mollusc", color: "#D2EAFF" },
        { id: 12, img: mustard_icon, name: "Mustard", value: "Mustard", color: "#FFF5CA" },
        { id: 13, img: sesameseeds_icon, name: "Sesame Seeds", value: "Sesame Seeds", color: "#F5E5C1" },
        { id: 14, img: sulphurdioxide_icon, name: "Sulphur Dioxide", value: "Sulphur Dioxide", color: "#E8F8FF" },
      ],
      spiceLevelData: [
        { id: 1, img: mild_icon, name: "Mild", value: "Mild" },
        { id: 2, img: spicy_icon, name: "Spicy", value: "Spicy" },
        { id: 3, img: extraspicy_icon, name: "Extra Hot", value: "Extra Hot" },
      ],
      foodLablesData: [],
      selectedFoodLabels: [],
      appliedFoodLables: [],
      isFoodLabelOpen: true,
      expanded: "" || false,
      isDialogOpen: false,
      pagination: {},
      packageBreadth:10,
      packageHeight:10,
      packageWidth:10,
      packageWeight:0.5,
      menuAnchorEl: null,
      dialogCsvOpen: false,
      fileCsvClose: false,
      selectedCsvFile: undefined,
      csvFileError: "",
      showCsvUploadDialog: false,
      allChecked: false,
      checked: false,
      checkedImageCatalogueList: false,
      openDeleteDialog: false,
      selectedItemCount: 0,
      imageSignedId: [],
      compressedFiles: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
    // Customizable Area Start

  }

  // Customizable Area Start
  /* istanbul ignore next */
  async componentDidMount() {
    this.searchProduct();
    this.getCategories();
    this.getCurrencyType();
    this.getOrderNotification()
    this.checkImportProcess()
    this.setState({
      foodLablesData: [{ id: 1, name: "Dietary", children: [...this.state.dietaryData] },
      { id: 2, name: "Allergens", children: [...this.state.allergenData] },
      { id: 3, name: "Spice Level", children: [...this.state.spiceLevelData] }
      ]
    })
    this.mixPanelInfo = JSON.parse(await getStorageData("mixPanelInfo"))
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleFlashMessageFromLibrary(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId == this.errorCSVCallId) {
        if (responseJson.data.attributes.success_count > 0 && responseJson.data.attributes.total_count == responseJson.data.attributes.success_count) {
          this.setState({ importStatus: 'success', importPopup: true, successfulUploads: responseJson.data.attributes.success_count, failedUploads:0,errorCSVFile:''})
        }
       else if (responseJson.data.attributes.success_count > 0 && responseJson.data.attributes.total_count > responseJson.data.attributes.success_count) {
          let csvFile = responseJson.data.attributes.error_file
          this.setState({ importStatus: 'partiallySuccess', importPopup: true,successfulUploads: responseJson.data.attributes.success_count, failedUploads:responseJson.data.attributes.failed_count,errorCSVFile:csvFile })
        }
        else if(responseJson.data.attributes.success_count == 0 && responseJson.data.attributes.total_count > responseJson.data.attributes.success_count)
        {
          let csvFile = responseJson.data.attributes.error_file
          this.setState({ importStatus: 'failed', importPopup: true, successfulUploads: 0, failedUploads:responseJson.data.attributes.failed_count,errorCSVFile:csvFile})
        }
        else{
          this.setState({ importStatus: 'unknownIssue', importPopup: true, successfulUploads: 0, failedUploads:0,errorCSVFile:''})

        }
      }
      this.manageApiRes(apiRequestCallId, responseJson)
      
      this.messageUploadCsvError(message, apiRequestCallId, responseJson)
      this.manageImportCSVProgressResponse(apiRequestCallId, responseJson)
      this.messageMenuItemsDelete(message, apiRequestCallId, responseJson)

    }
    this.messageUploadCsvFileMessage(message)
    // Customizable Area End

  }

  // Customizable Area Start
  manageApiRes = (apiRequestCallId: string, responseJson: any) => {
    if (responseJson?.data && !responseJson.errors && !responseJson.error) {
      this.apiRequestCall(apiRequestCallId, responseJson);
    } else if (apiRequestCallId === this.searchProductApiCallId && responseJson.message == "Products Not Found") {
      this.setState({ loadingSearch: false, noSearchCategories: true })
    } else if (apiRequestCallId === this.getCurrencyTypeApiCallId) {
      if (responseJson.currency_symbol) {
        setStorageData("currency_type", responseJson.currency_symbol);
      } else {
        setStorageData("currency_type", "$");
      }
    } else if (apiRequestCallId == this.getOrderNotificationId) {
      emitter.emit("changeNotiNumber", responseJson.new_order_count)
    } else if (apiRequestCallId == this.imageVariantsApiCallId) {
      this.variantImagesRes(responseJson);
    }
  }
  variantImagesRes = (responseJson:any) => {
    if(responseJson){
      uploadImageToS3(this.state.compressedFiles[0], responseJson?.image?.url, responseJson?.image?.headers?.['Content-MD5'])
      uploadImageToS3(this.state.compressedFiles[1], responseJson?.image_variant_1?.url, responseJson?.image_variant_1?.headers?.['Content-MD5'])
      uploadImageToS3(this.state.compressedFiles[2], responseJson?.image_variant_2?.url, responseJson?.image_variant_2?.headers?.['Content-MD5'])
      uploadImageToS3(this.state.compressedFiles[3], responseJson?.image_variant_3?.url, responseJson?.image_variant_3?.headers?.['Content-MD5'])
    
    this.setState({
      product_selectedImage : [
        {"image": responseJson?.image?.signed_id},
        {"image_variant_1": responseJson?.image_variant_1?.signed_id},
        {"image_variant_2": responseJson?.image_variant_2?.signed_id},
        {"image_variant_3": responseJson?.image_variant_3?.signed_id},
      ],
      openSubmit: true
    })
  }
  }

  handleMessage = (location: H.Location, action: H.Action) => {
    if (this.state.isAddEdit && action === "POP") {
      this.setState({
        isAddEdit: false,
        openSubmit: false,
        searchProductVal: "",
        noSearchCategories: false,
      });
      return false
    } else {
      return true
    }
  }

  messageUploadCsvError = (message: Message, apiRequestCallId: string, responseJson: { message?: string, errors?: string, error?: string }) => {
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson?.errors) {
      if(apiRequestCallId === this.uploadCsvFileApiId){
        this.setState({
          dialogCsvOpen: true,
          csvFileError: "“Error”, your CSV file doesn't contain the header row or the headers do not match. Please refer the sample CSV."
        });
      }
      else{
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.setState({ loading: false, loadingSearch: false });
    }
  };

  messageMenuItemsDelete = (message: Message, apiRequestCallId: string, responseJson: { message?: string, errors?: string, error?: string }) => {
    if (this.deleteMenuItemsApiId === apiRequestCallId) {
      if (responseJson?.message) {
        this.setState({
          loading: false,
          selectedItemCount: 0,
          openDeleteDialog: false,
          openAlert: true,
          alertSuccess: true,
          alertMessage: `  “${this.state.selectedItemCount}” ${this.state.selectedItemCount == 1 ? "Item" : "Items"} deleted successfully.`,
        });
        this.searchProduct();
      } else if (responseJson?.errors) {
        this.searchProduct();
        this.setState({
        loading: false,
        openDeleteDialog: false,
        openAlert: true,
        alertSuccess: false,
        alertMessage:
          "Something went wrong.",
      });
      }
    }
  };

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderNotificationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_order_management/orders/new_order_count"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteDialogClose = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ menuAnchorEl: event.currentTarget });
    mixPanelEventHit("Menu items created",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleCsvClick = () => {
    this.setState({ dialogCsvOpen: true });
    mixPanelEventHit("CSV Import",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
  };

  messageUploadCsvFileMessage = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.uploadCsvFileApiId === apiRequestCallId) {
      if (responseJson.message) {
        this.checkImportProcess()
        this.handleCsvClose()
      }
    }
  };

  handleCsvClose = () => {
    this.setState({ dialogCsvOpen: false, csvFileError: "" });
  };

  uploadCsv = async () => {
    if (!this.state.selectedCsvFile) {
      this.setState({
        csvFileError: configJSON.csvFileError,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", this.state.selectedCsvFile);
    const message = await generateRequestMessage(
      configJSON.uploadCsvAPI,
      configJSON.httpPostType,
      {
        "content-type": undefined,
      }
    );
    this.uploadCsvFileApiId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    this.send(message);
  };

  handleCsvSelect = async (files: FileList) => {
    if (files && files.length > 0) {
      const selectedCsvFile = files[0];
  
      try {
        const text = await selectedCsvFile.text();
        const csvData = this.parseCsvFile(text);
        if (csvData.data.length >= 1000) {
          this.setState({
            selectedCsvFile,
            csvFileError: "“Error”, your CSV file contains more than 1000 items. Please remove additional items and re-upload."
          });
        } else {
          this.setState({
            selectedCsvFile,
            csvFileError: ""
          });
        }
      } catch (error) {
        
        this.setState({
          selectedCsvFile: undefined,
          csvFileError: "Error parsing CSV file."
        });
      }
    } else {
      this.setState({
        selectedCsvFile: undefined,
        csvFileError: "",
        fileCsvClose: true,
      });
    }
  };
  
  downloadItemsSampleCsv = async () => {
    const token = await getStorageData("admintoken");
    const urlFile = `${baseURL}/${configJSON.downloadItemsCsvAPI}?&token=${token}`
    const link = document.createElement("a");
    link.setAttribute("href", urlFile);
    link.setAttribute("download", "data.csv");
    link.setAttribute("rel", "noreferrer");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ alertMessage: 'CSV downloaded successfully.', openAlert: true, alertSuccess: true })
  };

  parseCsvFile = (text: any) => {
    const lines = text.split("\n");
    const headers = lines[0].split(",");
    const data = lines.slice(1).map((line: any) => line.split(","));
    return {
      headers: headers,
      data: data,
    };
  };
  
  handleSelectAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedCategories = this.state.productsData.map((data: any) => ({
      ...data,
      checked: isChecked,
    }));
    const selectedItemCount = isChecked ? updatedCategories.length : 0;
    this.setState({ 
      productsData: updatedCategories, 
      allChecked: isChecked, 
      checkedImageCatalogueList: isChecked,
      selectedItemCount 
    });
  };

  handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    const updatedCategories = this.state.productsData.map(
      (data: any) =>
        data.id === id ? { ...data, checked: event.target.checked } : data
    );
    const allChecked = updatedCategories.every(
      (data: { checked: boolean }) => data.checked
    );
    const oneChecked = updatedCategories.some(
      (data: { checked: boolean }) => data.checked
    );
    const selectedItemCount = updatedCategories.filter(
      (data: { checked: boolean }) => data.checked
    ).length;
    this.setState({
      productsData: updatedCategories,
      allChecked,
      checkedImageCatalogueList: oneChecked,
      selectedItemCount
    });
  };  

  resetCheckboxes = () => {
    const updatedCategories = this.state.productsData.map((data: any) => ({
      ...data,
      checked: false,
    }));
    this.setState({
      productsData: updatedCategories,
      allChecked: false,
      checkedImageCatalogueList: false,
    });
  };

  handleDeleteDialog = async () => {
    const { productsData } = this.state;
     let onlyIDs: number[] = []
     productsData?.forEach((category: MenuItemsFormatted) => {
      if (category.checked) {
        onlyIDs.push(Number(category.id))
      }
     });
     this.setState({ selectedItemCount: onlyIDs.length });
     await this.deleteMenuItems(onlyIDs);
     this.setState({
      searchProductVal: "",
     });
     this.resetCheckboxes();
  };

  deleteMenuItems = async (data: number[]) => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let body = {
      ids: data,
    };

    this.deleteMenuItemsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteMenuItemsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCurrencyType = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrencyTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_store_details/fetch_currency_symbol"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCurrencyType = async () => {
    this.currencyType = await getStorageData("currency_type") || "$"
  }

  pressBackEditAddItem = () => {
    if (this.state.isAddEdit) {
      this.setState({
        isAddEdit: false,
        openSubmit: false,
        searchProductVal: "",
        noSearchCategories: false,
      });
    }
  }

  apiRequestCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      const categories = responseJson?.data?.map((categoriesObj: any) => {
        return { id: categoriesObj.attributes.id, name: categoriesObj.attributes.name }
      })
      this.setState({ categoriesData: categories, loading: false });
    } else if (apiRequestCallId === this.addCategoryApiCallId) {
      const category = { id: responseJson.data.attributes.id, name: responseJson.data.attributes.name }
      this.setState({
        product_category: [...this.state.product_category, category], loading: false
      });
      this.getCategories();
    } else if (apiRequestCallId === this.addProductApiCallId) {
      this.addProductApiCall(responseJson);
    } else if (apiRequestCallId === this.editProductApiCallId) {
      this.editProductApiCall(responseJson);
    } else if (apiRequestCallId === this.searchProductApiCallId) {
      this.setState({ loadingSearch: false, noSearchCategories: false, productsData: responseJson.data, pagination: responseJson?.metadata?.meta?.pagination });
    } else if (apiRequestCallId === this.searchSuggestiveProductApiCallId) {
      if (responseJson.data.length > 0 || responseJson.categories.length > 0) {
        const product = responseJson?.data?.map((productVal: any) => {
          return productVal.attributes
        })
        this.setState({ loadingSearch: false, productSearchData: [...product, ...responseJson?.categories] });
      } else {
        this.setState({ loadingSearch: false, productSearchData: [] });
      }
    }
  }
  manageImportCSVProgressResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId == this.importCSVProgressCallId) {
      if (!responseJson.message) {
        if (responseJson.upload_completed == false) {
          this.setState({ importInProgress: true, progress: responseJson.progress_percentage })
          setTimeout(() => {
            this.checkImportProcess()
          }, 3000)
        } else {
          this.setState({ importInProgress: false, menuAnchorEl: null})
          this.errorCSVAPI();
        }
      }
      else {
        this.setState({ importInProgress: false })
      }
    }
    if(apiRequestCallId == this.resetProcessCallId) {
      this.searchProduct()
    }
  }
  editProductApiCall = (responseJson: any) => {
    if (responseJson.data.type == 'error' && responseJson.data.attributes.errors.name[0] == "This product has already been taken.") {
      this.setState({ loading: false, setTouched: true, errorText: "This product title has already been taken. Please enter different title" })
    } else {
      this.setState({ loading: false, noSearchCategories: false, isAddEdit: false, setTouched: false, openAlert: true, alertSuccess: true });
      if (this.state.productStatus) {
        if (responseJson?.data?.attributes?.status) {
          this.setState({ alertMessage: "The item is visible on the website now." });
        } else {
          this.setState({ alertMessage: "The item is hidden from the website now." });
        }
      } else {
        this.setState({ alertMessage: "Item edited successfully." });
      }
      this.setInitialState();
      this.searchProduct();
    }
  }
  addProductApiCall = (responseJson: any) => {
    if (responseJson?.data?.type == 'error' && responseJson?.data?.attributes?.errors?.name[0] == "This product has already been taken.") {
      this.setState({ loading: false, setTouched: true, errorText: "This product title has already been taken. Please enter different title" })
    } else {
      this.setInitialState();
      this.searchProduct();
      this.setState({ loading: false, isAddEdit: false, noSearchCategories: false, setTouched: false, openAlert: true, alertSuccess: true, alertMessage: "Item added successfully." });
    }
  }
  discardBtn = (even: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isAddEdit: false, openSubmit: false, productSearchData: [], searchProductVal: "" })
    this.searchProduct();
  }

  setInitialState = () => {
    this.setState({
      product_Id: "",
      product_title: "",
      product_desc: "",
      product_category: [],
      product_previewImage: null,
      product_selectedImage: null,
      product_price: "",
      discount_checked: false,
      product_discountPrice: 0,
      product_discountValue: "",
      product_dietary: [],
      product_allergens: [],
      product_spicelevel: "",
      product_status: false,
      product_stock: "",
      titleCount: 0,
      descCount: 0,
      image_check: false,
      searchProductVal: "",
      noSearchCategories: false,
      productVariants: [],
      productStatus: false,
      productSearchData: [],
      packageBreadth: 10,
      packageHeight: 10,
      packageWidth: 10,
      packageWeight: 0.5,
    });
  };

  editProductDetails = (values: any, productId: any) => {
    const category: any = [];
    values.categories[0].map((val: any) => {
      category.push(this.state.categoriesData.find((categorieObj: any) => categorieObj.id === val[1]))
    });
    const dietary: any = [];
    const allergen: any = [];
    this.state.allergenData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        allergen.push(item.name);
      }
    });
    this.state.dietaryData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        dietary.push(item.name);
      }
    });
    const spiceLevel: any = [];
    this.state.spiceLevelData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        spiceLevel.push(item.name);
      }
    });

    this.setState({
      product_Id: productId,
      isEdit: true,
      isAddEdit: true,
      product_title: values.name,
      product_desc: values.description,
      product_category: category,
      product_price: values.price,
      discount_checked: values.discount > 0 ? true : false,
      product_discountValue: values.discount ?? 0,
      product_discountPrice: values.after_discount,
      product_status: values.status,
      product_stock: values.availability,
      product_dietary: dietary,
      product_allergens: allergen,
      product_spicelevel: spiceLevel.length > 0 ? spiceLevel[0] : "",
      titleCount: values.name?.length,
      descCount: values.description?.length,
      productVariants: values.variants,
      packageHeight: values.height,
      packageBreadth: values.breadth,
      packageWidth: values.length,
      packageWeight: values.weight,
      imageError: "",
      openSubmit: false,
      product_previewImage: values?.images.url
    });
  };
  addProduct = async () => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("name", this.state.product_title.trim());
    formData.append("description", this.state.product_desc);
    formData.append("price", this.state.product_price);
    formData.append("discount", this.state.product_discountValue);
    formData.append("image", this.state.product_selectedImage[0]?.image);
    formData.append("image_variant_1", this.state.product_selectedImage[1]?.image_variant_1);
    formData.append("image_variant_2", this.state.product_selectedImage[2]?.image_variant_2);
    formData.append("image_variant_3", this.state.product_selectedImage[3]?.image_variant_3);
    formData.append("availability", this.state.product_stock);
    formData.append("status", JSON.stringify(this.state.product_status));
    formData.append("height", Number(this.state.packageHeight).toFixed(2));
    formData.append("breadth", Number(this.state.packageBreadth).toFixed(2));
    formData.append("length", Number(this.state.packageWidth).toFixed(2));
    formData.append("weight", Number(this.state.packageWeight).toFixed(2));
    formData.append(
      "category_ids[]",
      this.state.product_category
        .map((productVal: any) => {
          return productVal.id;
        })
        .join(",")
    );
    this.state.product_allergens.forEach((labelVal: any) => {
      formData.append("lables[]", labelVal);
    });
    this.state.product_dietary.forEach((labelVal: any) => {
      formData.append("lables[]", labelVal);
    });
    if (this.state.product_spicelevel) {
      formData.append("lables[]", this.state.product_spicelevel);
    }
    if (this.state.productVariants.length > 0) {
      this.state.productVariants.map((variant: any) => {
        formData.append("catalogue_variant_ids[]", variant.id);
      })
    } else {
      formData.append("catalogue_variant_ids[]", "");
    }
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editStatusProduct = async (Categories: any, status: any, productId: any) => {
    this.setState({ loading: true });
    const category: any = [];
    Categories.map((categoriVal: any) => {
      category.push(this.state.categoriesData.find((categoryObj: any) => categoryObj.id === categoriVal[1]))
    });
    let formData = new FormData();
    formData.append("status", JSON.stringify(status));
    formData.append(
      "category_ids[]",
      category
        .map((categoriVal: any) => {
          return categoriVal.id;
        })
        .join(",")
    );
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint + "/" + productId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editProduct = async () => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("name", this.state.product_title.trim());
    formData.append("description", this.state.product_desc);
    formData.append(
      "category_ids[]",
      this.state.product_category
        .map((categoriVal: any) => {
          return categoriVal.id;
        })
        .join(",")
    );
    formData.append("price", this.state.product_price);
    if(this.state.product_selectedImage){
      formData.append("image", this.state.product_selectedImage[0]?.image);
      formData.append("image_variant_1", this.state.product_selectedImage[1]?.image_variant_1);
      formData.append("image_variant_2", this.state.product_selectedImage[2]?.image_variant_2);
      formData.append("image_variant_3", this.state.product_selectedImage[3]?.image_variant_3);
    }
    formData.append("discount", this.state.product_discountValue);
    if (this.state.product_allergens.length > 0 || this.state.product_dietary.length > 0 || this.state.product_spicelevel != "") {
      this.state.product_allergens.forEach((labelVal: any) => {
        formData.append("lables[]", labelVal);
      });
      this.state.product_dietary.forEach((labelVal: any) => {
        formData.append("lables[]", labelVal);
      });
      if (this.state.product_spicelevel) {
        formData.append("lables[]", this.state.product_spicelevel);
      }
    } else {
      formData.append("lables[]", "");
    }
    if (this.state.productVariants.length > 0) {
      this.state.productVariants.map((variant: any) => {
        formData.append("catalogue_variant_ids[]", variant.id);
      })
    } else {
      formData.append("catalogue_variant_ids[]", "");
    }

    formData.append("availability", this.state.product_stock);
    formData.append("status", JSON.stringify(this.state.product_status));
    formData.append("height", Number(this.state.packageHeight).toFixed(2));
    formData.append("breadth", Number(this.state.packageBreadth).toFixed(2));
    formData.append("length", Number(this.state.packageWidth).toFixed(2));
    formData.append("weight", Number(this.state.packageWeight).toFixed(2));
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint + "/" + this.state.product_Id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCategories = async () => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    setTimeout(() => {
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }, 2000)
  };
  handleDiscountValueChange = (event: any) => {
    const discountAfterPrice =
      this.round((this.state.product_price - ((this.state.product_price * event.target.value) / 100)), 2);
    this.setState({
      product_discountValue: event.target.value,
      product_discountPrice: discountAfterPrice,
      openSubmit: true,
    });
  };
  round = (value: number, decimals: number) => {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }
  handleClickSpiceLevelChange = (event: any) => {
    if (event.target.value == 0 || event.target.value == undefined) {
      this.setState({ product_spicelevel: "", openSubmit: true });
    }
  };
  handleSpiceLevelChange = (event: any) => {
    this.setState({ product_spicelevel: event.target.value, openSubmit: true });
  };

  handleAllergensChange = (event: any) => {
    this.setState({ product_allergens: event.target.value, openSubmit: true });
  };

  handleDietaryChange = (event: any) => {
    this.setState({
      product_dietary: event.target.value,
      openSubmit: true,
    });
  };

  handleImageChange = (event: any) => {
    const inputElement = event.target;
    if (inputElement.files && inputElement.files[0]) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedTypes.includes(inputElement.files[0].type)) {
        if (inputElement.files[0].size > (5242880)) {
          this.setState({ image_check: true, product_selectedImage: null, product_previewImage: null, imageError: "Image size can not more than 5 MB" })
        } else {
          this.setState({image_check: false });
          const product_selectedImage = inputElement.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
            this.setState({
              product_previewImage: reader.result,
            });
          };
          reader.readAsDataURL(product_selectedImage);
          this.variantImages(product_selectedImage)
        }
      }
      else {
        this.setState({ image_check: true, product_selectedImage: null, product_previewImage: null, imageError: "Invalid file type. Please select a valid image. ex - jpg, jpeg, png etc." })
      }
    } else {
      if (this.state.product_previewImage == null) {
        this.setState({ image_check: true, imageError: "Image is required!" })
      }
    }
  };

  variantImages = (product_selectedImage : any) => {
    let variantsImages = processImage(product_selectedImage,[42, 200, 427])
    variantsImages?.then((result : any)=> {
      let files : any[] = []
      setTimeout(() => {
        for(const file of result){
            files.push(file?.compressedFile)
        }
        this.setState({
          compressedFiles : files
        }, ()=> {
          this.imageCompressCatelogApiCall(result)
        })
    }, 2000);
    })
  }

  imageCompressCatelogApiCall = async (body: any) => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const bodyParaCatelog = {
      "image": {
        "filename": body[0]?.filename,
        "size": body[0]?.size,
        "checksum": body[0]?.checksum
    },
    "image_variant_1": {
      "filename": body[1]?.filename,
      "size": body[1]?.size,
      "checksum": body[1]?.checksum
    },
    "image_variant_2": {
      "filename": body[2]?.filename,
      "size": body[2]?.size,
      "checksum": body[2]?.checksum
  },  
  "image_variant_3": {
    "filename": body[3]?.filename,
    "size": body[3]?.size,
    "checksum": body[3]?.checksum
  }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.imageVariantsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_catalogue/catalogues/image_presigned_urls"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyParaCatelog)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleParentChange = (isChecked: boolean, parent: { id: string; name: string; children: Array<ItemMenuFilter> }) => {
    let newSelected = [...this.state.selectedFoodLabels];
    if (!isChecked) {
      const arrayItemAdd: Array<string> = []
      parent.children.forEach((item: ItemMenuFilter) => {
        const indexItem = this.state.selectedFoodLabels.findIndex((label: string) => label === item.value)
        if (indexItem < 0) {
          arrayItemAdd.push(item.value)
        }
      })
      newSelected = [...newSelected, ...arrayItemAdd]
    } else {
      const arrayRemoveItem = parent.children.map((item: ItemMenuFilter) => item.value)
      newSelected = difference(newSelected, arrayRemoveItem)
    }
    this.setState({ selectedFoodLabels: newSelected });
  }

  handleOpenAccordion = (name: string) => {
    if (this.state.expanded == name) {
      this.setState({ expanded: "" })
    } else {
      this.setState({ expanded: name })
    }
  }

  handleAlertMessageClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openAlert: false });
  };

  searchProduct = async (page = 1) => {
    this.setState({ loadingSearch: true });
    const token = await getStorageData("admintoken");

    let apiEndPoint = configJSON.searchProductAPIEndPoint + this.state.searchProductVal + "&page=" + page + "&per_page=10";
    if (this.state.appliedFoodLables.length > 0) {
      this.state.appliedFoodLables.map((foodLabel: any) => {
        apiEndPoint += "&search_lable[]=" + foodLabel
      })
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  searchProductWithText = (productVal: any) => {
    mixPanelEventHit("Admin menu item Search",{buildCardId : this.mixPanelInfo?.buildCardId, emailId : this.mixPanelInfo?.emailId})
    if (productVal == "") {
      this.setState({ searchProductVal: "", noSearchCategories: false, productSearchData: [] })
      this.searchProduct();
    } else {
      this.setState({ searchProductVal: productVal }, () => {
        this.resetCheckboxes();
      });
      this.searchProduct();
      if (productVal.length > 2) {
        this.searchSuggestiveProduct();
      } else {
        this.setState({
          productSearchData: []
        })
      }
    }
  }
  deleteProductVariant = async () => {
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.VariantApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductVariantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVariantAPIEndPoint +
      this.state.deleteVariantId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  createCategory = async (value: any) => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCategoryAPIEndPoint + value
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchSuggestiveProduct = async () => {
    this.setState({ loadingSearch: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchSuggestiveProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchSuggestiveProductAPIEndPoint + this.state.searchProductVal
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
    navigateTo.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "admin/login"
    );
    navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigateTo.messageId, navigateTo);
  };

  handleDialogOpen = () => {
    this.setState({ selectedFoodLabels: this.state.appliedFoodLables })
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  setIsFoodLabelOpen = () => {
    this.setState({ isFoodLabelOpen: true });
  };

  setIsFoodLableClose = () => {
    this.setState({ isFoodLabelOpen: false });
  };

  handlePageChange = (page: any) => {
    this.searchProduct(page)
    this.resetCheckboxes()
  }

  openAddItemsForm = () => {
    this.setState({
      isAddEdit: true,
      isEdit: false,
    }, () => {
      this.setInitialState();
    })
  }
  navigateToItemsLibrary = () => {
    removeStorageData(configJSON.STORED_SEACHDATA_KEY);
    const toItemLibraryMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
    toItemLibraryMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "InventoryManagementLibrary"
    );
    toItemLibraryMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toItemLibraryMessage);
  }

  handleFlashMessageFromLibrary = (message: Message) => {
    if (message.id === getName(MessageEnum.PostEntityMessage)) {
      if (message.properties.PostDetailDataMessage.success) {
        this.setState({
          openAlert: true,
          alertSuccess: true,
          alertMessage: message.properties.PostDetailDataMessage.message
        })
      }
    }
  }

  onChangePackageHeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const height = event.target.value;
    if (this.isDecimalValid(height)) {
      this.setState({
        openSubmit: true,
        packageHeight: height,
        setTouched: false,
      })
      this.formikRef?.current?.setFieldValue(
        "packageHeight",
        height
      );
    }
  }

  onChangePackageBreadth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const breadth = event.target.value;
    if (this.isDecimalValid(breadth)) {
      this.setState({
        packageBreadth: breadth,
        openSubmit: true,
        setTouched: false,
      })
      this.formikRef?.current?.setFieldValue(
        "packageBreadth",
        breadth
      );
    }
  }

  onChangePackageWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const width = event.target.value;
    if (this.isDecimalValid(width)) {
      this.setState({
        setTouched: false,
        openSubmit: true,
        packageWidth: width,
      })
      this.formikRef?.current?.setFieldValue(
        "packageWidth",
        width
      );
    }
  }

  onChangePackageWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const weight = event.target.value;
    if (this.isDecimalValid(weight)) {
      this.setState({
        packageWeight: weight,
        setTouched: false,
        openSubmit: true,
      })
      this.formikRef?.current?.setFieldValue(
        "packageWeight",
        weight
      );
    }

  }
  isDecimalValid = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value) || value === '') {
      return true;
    }

    return false;
  }
  getItemFieldErrorData = (touchedData: boolean | undefined, errorData: string | undefined) => {
    const { setTouched, errorText } = this.state;
    return {
      hasError: Boolean(touchedData && errorData) || setTouched,
      errorText: setTouched ? errorText : touchedData && errorData
    }
  }
  getItemFieldValidations = (touched: FormikTouched<FormikItemValues>, errors: FormikErrors<FormikItemValues>) => {
    const heightData = this.getItemFieldErrorData(touched.packageHeight, errors.packageHeight);
    const breadthData = this.getItemFieldErrorData(touched.packageBreadth, errors.packageBreadth);
    const widthData = this.getItemFieldErrorData(touched.packageWidth, errors.packageWidth);
    const weightData = this.getItemFieldErrorData(touched.packageWeight, errors.packageWeight);
    return {
      packageHeightError: heightData.hasError,
      packageHeightErrorText: heightData.errorText,
      packageBreadthError: breadthData.hasError,
      packageBreadthErrorText: breadthData.errorText,
      packageWidthError: widthData.hasError,
      packageWidthErrorText: widthData.errorText,
      packageWeightError: weightData.hasError,
      packageWeightErrorText: weightData.errorText,
    }
  }

  checkImportProcess = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importCSVProgressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkImportProgress
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  errorCSVAPI = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.errorCSVCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.errorCSV
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  resetProcess = async () => {
    this.setState({ importPopup: false, selectedCsvFile: undefined})
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetProcessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_data_import_export/import_items/reset_process'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  resetProcess2 = async ()=>{
    this.resetProcess()
    this.handleCsvClick()

  }

 
  // Customizable Area End
}